import '@plugins/jquery.autocomplete/jquery.autocomplete.css';
import '@plugins/jquery.autocomplete/jquery.autocomplete.min';

const $carouselsInfoteka = $('.webinar-block__inner');

const initCarousel = (carousels, config, callback = null) => {
    const defaultConfig = {
        loop: true,
        autoplay: false,
        nav: true,
        dots: false,
        navText: [
            '<img src="/build/img/landing/icon/left.svg" alt="Slide left">',
            '<img src="/build/img/landing/icon/right.svg" alt="Slide right">',
        ],
    };

    if (carousels.length) {
        carousels.owlCarousel({...defaultConfig, ...config});
        if (callback !== null) {
            callback(carousels);
        }
    }
};

const infotekaConfig = {
    items: 3,
    loop: true,
    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 5000,
    nav: false,
    dots: true,
    dotsEach: true,
    navContainer: '.webinar-block__inner',
    responsiveClass: true,
    responsive: {
        400: {
            items: 3,
            nav: true,
            margin: 14,
        },
        992: {
            items: 4,
            margin: 61,
            nav: false,
        },
        1200: {
            items: 5,
            nav: true,
            margin: 96,
        },

        1920: {
            items: 6,
            nav: true,
            margin: 96,
        },
    },
};
initCarousel($carouselsInfoteka, infotekaConfig, (carousels) => {
    const $prevBtn = carousels.find('.owl-prev');
    $prevBtn.remove();
    const $nextBtn = carousels.find('.owl-next');
    $nextBtn.remove();
});
